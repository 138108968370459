<script lang="ts" setup>
import type { StyleValue } from 'vue';
import { computed, onMounted, ref, watch } from 'vue';
import { lightenDarkenColor } from '@gem/control';
import { Color } from '../../color-picker/Color';
import type { WhereInput } from '../../color-picker/types';
import colorTransparentImg from './images/color-transparent-preview.png';

type Props = {
  value?: string;
  isAutoUpdateValue?: boolean;
  hexClass?: string;
  alphaClass?: string;
  colorSvgEyeDropper?: string;
  bgHoverSvgEyeDropper?: string;
};

const props = withDefaults(defineProps<Props>(), {
  hexClass: 'text-white',
  alphaClass: 'text-white',
  colorSvgEyeDropper: '#E2E2E2',
  bgHoverSvgEyeDropper: 'hover:bg-[#5b5b5b]',
});

const emit = defineEmits<{
  (e: 'change-color', input: string, whereInput?: WhereInput): void;
  (e: 'open-picker', input: boolean): void;
}>();

const hexInputEL = ref<HTMLInputElement>();
const inputOpacity = ref<HTMLInputElement>();
const showPicker = ref(false);
const color = ref(Color.fromString(props.value));
const hueArea = ref<HTMLDivElement>();
const huePicker = ref<HTMLDivElement>();
const surface = ref<HTMLDivElement>();
const picker = ref<HTMLDivElement>();
const alphaArea = ref<HTMLDivElement>();
const alphaPicker = ref<HTMLDivElement>();
const iframeDocument = ref<HTMLIFrameElement>();
const isFocus = ref(false);
const isHover = ref(false);
let debounceChangeValue: ReturnType<typeof setTimeout>;

const limitDim = (pos: number, dimension: number) => {
  return Math.max(-4, Math.min(pos - 4, dimension - 12));
};

const alphaPickerStyle = computed<StyleValue>(() => {
  const rect = alphaArea.value?.getBoundingClientRect();

  if (rect) {
    const left = Math.round(rect.width * color.value.a);
    return {
      left: `${limitDim(left, rect.width)}px`,
      background: color.value.getRGBA(),
    };
  }
  return {};
});

const pickerStyle = computed<StyleValue>(() => {
  const rect = surface.value?.getBoundingClientRect();
  let value = color.value.value;
  if (color.value.format === 'HSV') value = color.value.value;
  if (color.value.format === 'HSL') value = color.value.lightness;
  if (rect) {
    const width = rect.width;
    const height = rect.height;
    const x = ((color.value.saturation * width) / 100) | 0;
    const y = (height - (value * height) / 100) | 0;
    return {
      transform: `translate(${limitDim(x, width)}px, ${limitDim(y, height)}px)`,
      background: `rgb(${color.value.r}, ${color.value.g}, ${color.value.b})`,
    };
  }
  return {};
});

const hueStyle = computed<StyleValue>(() => {
  const rect = hueArea.value?.getBoundingClientRect();
  const left = ((color.value.hue * (rect?.width ?? 0)) / 360) | 0;
  return {
    left: `${limitDim(left, rect?.width ?? 248)}px`,
    background: `hsl(${color.value.hue}, 100%, 50%)`,
  };
});

const alphaStyle = computed<StyleValue>(() => {
  return {
    background: `linear-gradient(to left, ${color.value.formatRGBA(1)} 0%, ${color.value.formatRGBA(0)} 100%)`,
  };
});

const surfaceStyle = computed<StyleValue>(() => {
  const nc = color.value.clone();
  nc.setHSV(nc.hue, 100, 100);
  return { background: `hsl(${color.value.hue}, 100%, 50%)` };
});

const noHashtagHexColor = ref(props.value ? color.value?.getHexa()?.replace('#', '') : '');

function changeColor(whereInput?: WhereInput) {
  clearTimeout(debounceChangeValue);
  changeTagHexColor();
  debounceChangeValue = setTimeout(
    () => {
      emit('change-color', color.value.getColor(), whereInput);
    },
    whereInput === 'hexInput' ? 200 : whereInput === 'opacityInput' ? 500 : 20,
  );
}

function onChangeHexValue(e: Event) {
  clearTimeout(debounceChangeValue);
  const value = (e.target as HTMLInputElement).value;
  if (!value) {
    noHashtagHexColor.value = '';
    emit('change-color', '');
  }

  const noHashtagHexRegexp = /^[0-9A-F]{6}$/i;
  if (noHashtagHexRegexp.test(value)) {
    color.value = Color.fromString('#' + value);
    changeColor('hexInput');
    return;
  }

  const rgbaRegexp = /^rgb(a?)[(]\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*(?:,\s*([\d.]+)\s*)?[)]$/;
  const hexRegexp = /^#[0-9A-F]{6}$/i;
  const hexWithoutPrefixRegexp = /^[0-9A-F]{6}$/i;
  if (hexRegexp.test(value) || rgbaRegexp.test(value) || hexWithoutPrefixRegexp.test(value)) {
    color.value = Color.fromString(value);
    changeColor('hexInput');
  }
}

function onChangeAlphaValue(e: Event) {
  clearTimeout(debounceChangeValue);
  const inputVal = Number((e.target as HTMLInputElement).value);
  const value = Number((Math.max(0, Math.min(100, inputVal)) / 100).toFixed(2));
  if (value >= 0 && value <= 1) {
    color.value.setAlpha(value);
    changeColor('opacityInput');
  }
}

function updateColor(e: MouseEvent) {
  if (surface.value && picker.value) {
    const rect = surface.value.getBoundingClientRect();
    let x = e.pageX - rect.x;
    let y = e.pageY - rect.y;

    const width = rect.width;
    const height = rect.height;

    if (x > width) x = width;
    if (y > height) y = height;
    if (x < 0) x = 0;
    if (y < 0) y = 0;

    const value = (100 - (y * 100) / height) | 0;
    const saturation = ((x * 100) / width) | 0;

    if (color.value.format === 'HSV') color.value.setHSV(color.value.hue, saturation, value);
    if (color.value.format === 'HSL') color.value.setHSL(color.value.hue, saturation, value);

    changeColor();
  }
}

function updateHueSlider(e: MouseEvent) {
  if (hueArea.value && huePicker.value) {
    const rec = hueArea.value.getBoundingClientRect();
    let x = e.pageX - rec.x;
    const width = rec.width;

    if (x < 0) x = 0;
    if (x > width) x = width;

    // TODO 360 => 359
    let hue = Math.floor((359 * x) / width);

    if (hue === 360) hue = 359;
    color.value.setHue(hue);

    if (color.value.saturation === 0) {
      color.value.setHSL(hue, 100, 50);
    }

    changeColor();
  }
}

function updateAlphaSlider(e: MouseEvent) {
  if (alphaArea.value && alphaPicker.value) {
    const alphaRect = alphaArea.value.getBoundingClientRect();
    let x = e.pageX - alphaRect.x;
    const width = alphaRect.width;

    if (x < 0) x = 0;
    if (x > width) x = width;
    color.value.a = parseFloat((x / width).toFixed(2));

    changeColor();
  }
}

function setMouseTracking(elem: HTMLElement, callback: (e: MouseEvent) => void) {
  document.addEventListener('mouseup', function () {
    document.removeEventListener('mousemove', callback);
  });

  iframeDocument.value?.addEventListener('mouseup', function () {
    document.removeEventListener('mousemove', callback);
  });

  elem.addEventListener('mousedown', function (e) {
    e.preventDefault();
    callback(e);
    document.addEventListener('mousemove', callback);
  });
}

function getIframeDocument() {
  const iframe = document.querySelector('iframe');
  if (!iframe) {
    return;
  }
  iframeDocument.value = iframe.contentDocument || (iframe.contentWindow as any).document;
}

const stopPropagation = (e: Event) => {
  e.stopPropagation();
};

const disableShortcutDelete = (e: KeyboardEvent) => {
  if (e.key === 'Delete') {
    e.stopPropagation();
  }
};

declare global {
  interface Window {
    EyeDropper: any;
  }
}

interface EyeDropperConstructor {
  new (): EyeDropperInterface;
}

interface ColorSelectionOptions {
  signal: AbortSignal;
}

interface ColorSelectionResult {
  sRGBHex: string;
}

interface EyeDropperInterface extends EyeDropperConstructor {
  open: (options?: ColorSelectionOptions) => Promise<ColorSelectionResult>;
}

declare let EyeDropper: {
  prototype: EyeDropperInterface;
  new (): EyeDropperInterface;
};

const isCanUseEyeDropper = computed(() => {
  return window?.EyeDropper;
});

const openEyeDropper = () => {
  clearTimeout(debounceChangeValue);
  const eyeDropper = new EyeDropper();
  eyeDropper
    .open()
    .then((result: any) => {
      color.value = Color.fromString(result.sRGBHex);
      changeColor('hexInput');
    })
    .catch((e: any) => {
      console.error(e);
    });
};

function changeTagHexColor() {
  noHashtagHexColor.value = color.value?.getHexa()?.replace('#', '');
}

onMounted(() => {
  getIframeDocument();
  if (surface.value) {
    setMouseTracking(surface.value, updateColor);
  }

  if (hueArea.value) {
    setMouseTracking(hueArea.value, updateHueSlider);
  }
  if (alphaArea.value) {
    setMouseTracking(alphaArea.value, updateAlphaSlider);
  }
});

watch(
  () => props.value,
  (newV) => {
    if (!props.isAutoUpdateValue) {
      return;
    }

    setTimeout(() => {
      color.value = Color.fromString(newV);
      if (newV) {
        changeTagHexColor();
      }
    }, 0);
  },
);

const setShowPicker = () => {
  showPicker.value = !showPicker.value;
};

const selectContentInput = () => {
  hexInputEL.value?.focus();
  hexInputEL.value?.select();
};

const selectOpacityContent = () => {
  inputOpacity.value?.focus();
  inputOpacity.value?.select();
};

watch(showPicker, (v) => {
  emit('open-picker', v);
});
</script>
<template>
  <div class="flex flex-col">
    <div class="flex items-center justify-between px-4">
      <div
        class="rounded-medium bg-dark-300 flex w-[232px] flex-nowrap items-center border border-transparent"
        :class="[{ 'border-primary-300': isFocus }, { 'bg-dark-200': isHover }]">
        <div class="group relative w-full">
          <div
            v-if="!noHashtagHexColor"
            class="absolute top-1/2 left-8 h-20 w-20 -translate-y-1/2 cursor-pointer"
            @click.stop="setShowPicker">
            <img :src="colorTransparentImg" alt="" />
          </div>
          <div
            v-else
            class="gemx-transparent-bg absolute top-1/2 left-8 h-20 w-20 -translate-y-1/2 cursor-pointer rounded-full"
            @click.stop="setShowPicker">
            <div
              class="h-full w-full rounded-full border border-transparent"
              :style="{ background: color.getColor(), 'border-color': lightenDarkenColor(color.getColor()) }"></div>
          </div>
          <input
            ref="hexInputEL"
            :value="noHashtagHexColor"
            :class="[hexClass, { '!border-transparent': isFocus }]"
            class="rounded-medium text-12 text-dark-high placeholder:text-dark-disabled disabled:border-dark-200 disabled:text-dark-disabled h-36 w-full border border-transparent bg-transparent px-8 pl-32 outline-none transition duration-200 disabled:cursor-not-allowed"
            type="text"
            placeholder="E.g FFFFFF"
            @click="selectContentInput"
            @mouseover="() => (isHover = true)"
            @mouseleave="() => (isHover = false)"
            @focus="() => (isFocus = true)"
            @blur="() => (isFocus = false)"
            @keydown="disableShortcutDelete"
            @mousedown="stopPropagation"
            @input="onChangeHexValue" />
        </div>
        <div v-if="noHashtagHexColor" class="relative h-full">
          <input
            ref="inputOpacity"
            :value="Math.round(color.a * 100)"
            :class="[
              alphaClass,
              { '!border-l-primary-300 !border-transparent ': isFocus },
              { '!border-l-dark-300': isHover && !isFocus },
            ]"
            class="rounded-medium text-12 text-dark-high placeholder:text-dark-disabled disabled:border-dark-200 disabled:text-dark-disabled h-36 w-[48px] w-full border border-transparent bg-transparent px-8 outline-none transition duration-200 disabled:cursor-not-allowed"
            type="number"
            min="0"
            max="100"
            @click="selectOpacityContent"
            @mouseover="() => (isHover = true)"
            @mouseleave="() => (isHover = false)"
            @focus="() => (isFocus = true)"
            @blur="() => (isFocus = false)"
            @keydown="disableShortcutDelete"
            @input="onChangeAlphaValue" />
          <span
            class="text-12 absolute top-1/2 right-8 flex h-full -translate-y-1/2 items-center justify-center pt-[1px]"
            :class="alphaClass"
            >%</span
          >
        </div>
      </div>
      <button
        v-if="isCanUseEyeDropper"
        class="eye-dropper rounded-medium bg-dark-300 flex h-36 w-36 cursor-pointer items-center justify-center"
        @click.stop="openEyeDropper">
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.9237 3.69542C13.8598 2.7645 15.3732 2.76875 16.304 3.70491C17.2349 4.64107 17.2306 6.15464 16.2945 7.08555L14.5154 8.85487L15.155 9.49486C15.4077 9.74781 15.4076 10.1578 15.1547 10.4106C14.9018 10.6634 14.4918 10.6633 14.239 10.4103L13.5972 9.76802L13.5901 9.77509L13.3652 9.54888L6.51877 16.396C5.7138 17.201 4.40869 17.201 3.60373 16.396C2.79876 15.5909 2.79876 14.2857 3.60373 13.4806L10.4576 6.62613L9.51212 5.67999C9.25935 5.42704 9.25948 5.01706 9.5124 4.76427C9.76533 4.51147 10.1753 4.5116 10.428 4.76455L11.1361 5.47315L12.9237 3.69542ZM11.3397 7.51178L4.48757 14.3646C4.17073 14.6814 4.17073 15.1952 4.48757 15.512C4.8044 15.8289 5.31809 15.8289 5.63493 15.512L12.4838 8.66247L11.3397 7.51178Z"
            fill="white" />
        </svg>
      </button>
    </div>
    <div :class="{ 'h-0 overflow-hidden': !showPicker }" class="flex flex-col gap-24">
      <div class="mt-16 h-[127px] px-4">
        <div ref="surface" :style="surfaceStyle" class="rounded-medium relative h-full cursor-pointer">
          <div class="gemx-picking-bg-1 rounded-medium absolute inset-0"></div>
          <div class="gemx-picking-bg-2 rounded-medium absolute inset-0"></div>
          <div
            v-show="noHashtagHexColor"
            ref="picker"
            class="gemx-pick-surface z-10 aspect-square w-16 cursor-pointer rounded-full border border-white will-change-transform"
            :style="pickerStyle"></div>
        </div>
      </div>
      <div class="flex items-center px-4">
        <div class="flex w-full flex-col gap-16">
          <div ref="hueArea" aria-label="Hue" class="gemx-hue relative h-8 rounded-[5px]">
            <div
              ref="huePicker"
              :style="hueStyle"
              class="absolute -inset-y-4 aspect-square w-16 cursor-pointer rounded-full border border-white"></div>
          </div>
          <div ref="alphaArea" class="gemx-alpha-bg mb-4 h-8 rounded-[5px]">
            <div class="relative h-full rounded-[5px]" :style="alphaStyle">
              <div
                :style="{
                  left: alphaPickerStyle.left,
                }"
                class="absolute -inset-y-4 aspect-square w-16 cursor-pointer rounded-full bg-white">
                <div
                  ref="alphaPicker"
                  :style="{
                    background: alphaPickerStyle.background,
                  }"
                  class="h-full w-full rounded-full border"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.gemx-picking-bg-1 {
  background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
}

.gemx-picking-bg-2 {
  background: linear-gradient(to bottom, transparent 0%, #000 100%);
}

.gemx-hue {
  background-image: linear-gradient(to right, red 0%, #ff0 17%, lime 33%, cyan 50%, blue 66%, #f0f 83%, red 100%);
}

.gemx-alpha-bg {
  background-image: url('../../../assets/opacity.png');
  background-size: cover;
}

.gemx-transparent-bg {
  background-image: url('../../../assets/transparent-color.svg');
  background-size: cover;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  appearance: none;
  -moz-appearance: textfield;
}
</style>
