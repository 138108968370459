<script lang="ts" setup>
import type { OptionSelect } from '@gem/uikit';
import { GSelect } from '@gem/uikit';
import { computed } from 'vue';
import { isDefined } from '../utils/common';

type PropsType = {
  id: string;
  value?: string | number;
  options?: {
    label?: string;
    value?: string;
    metaData?: any;
    hideOnPage?: string[];
  }[];
  readonly?: boolean;
  disableDefault?: boolean;
  searchable?: boolean;
  searchTerm?: string;
  pageType?: string;
  dropdownClasses?: string;
  searchInputClass?: string;
  floating?: boolean;
  placeholder?: string;
  fixWidth?: boolean;
};

const props = withDefaults(defineProps<PropsType>(), {
  floating: true,
  fixWidth: false,
});

const emit = defineEmits<{
  (e: 'controlChange', controlId: string, value?: any): void;
}>();

const activeOption = computed(() => {
  const selected = props.options?.find(
    (v) => v.value === props.value && !v?.hideOnPage?.includes(props?.pageType ?? ''),
  );
  if (selected) return selected;
  if (props.disableDefault) return undefined;
  return props.options?.[0];
});

const gSelectOptions = computed<OptionSelect[]>(() => {
  return (
    props.options
      ?.map((option) => {
        if (option.hideOnPage) {
          if (!option.hideOnPage.includes(props?.pageType ?? '')) {
            return { id: option.value ?? '', value: option, name: option.label?.toString() ?? '' };
          }
        } else {
          return { id: option.value ?? '', value: option, name: option.label?.toString() ?? '' };
        }
      })
      .filter(isDefined) ?? []
  );
});

const change = (_: any, selectedId: string) => {
  emit('controlChange', props.id, selectedId);
};
</script>

<template>
  <div class="gemx-control">
    <slot name="label"></slot>
    <div class="gemx-control gemx-control-select">
      <GSelect
        :dropdown-classes="dropdownClasses"
        :search-input-class="searchInputClass"
        :options="gSelectOptions"
        :active-id="activeOption?.value"
        :searchable="searchable"
        :search-term="searchTerm"
        :floating="floating"
        :placeholder="placeholder"
        :fix-width="fixWidth"
        @select="change">
        <template #button-content>
          <span class="text-12 inline-block truncate">{{ activeOption?.label }}</span>
        </template>
        <template #content="slotProps">
          <slot name="content" :props="slotProps" />
        </template>
      </GSelect>
    </div>
    <slot name="info"></slot>
    <slot name="modal"></slot>
  </div>
</template>
