<script lang="ts" setup>
import { flip, offset, shift, useFloating } from '@floating-ui/vue';
import { useToggle, useVirtualList } from '@vueuse/core';
import { onMounted } from 'vue';
import { watch } from 'vue';
import { computed, ref } from 'vue';

type OptionType = {
  label?: string;
  value?: string;
  metaData?: any;
  hideOnPage?: string[];
};
type PropsType = {
  id: string;
  value?: string;
  options?: OptionType[];
};

const props = defineProps<PropsType>();

const emit = defineEmits<{
  controlChange: [controlId: string, value?: any];
}>();

const select = ref<HTMLElement>();
const button = ref<HTMLElement>();

const keyword = ref('');

const { x, y, strategy } = useFloating(button, select, {
  strategy: 'absolute',
  placement: 'bottom-end',
  middleware: [flip(), shift(), offset(4)],
});

const items = computed(() => {
  return (
    props.options?.filter((v) => {
      return (
        v.label?.toLowerCase().includes(keyword.value.trim().toLowerCase()) ||
        v.value?.toLowerCase().includes(keyword.value.trim().toLowerCase())
      );
    }) ?? []
  );
});

const getMaxWidthItemValue = (optionSelect: OptionType[]) => {
  const maxLength = Math.max(...optionSelect.map((item) => item.label?.length || 0));
  const contentMaxLength = optionSelect.find((item) => item.label?.length === maxLength)?.label;

  let width = 0;
  const itemIconWidth = 36;
  const itemContentPaddingLeft = 8;
  const listPaddingX = 8;
  const delta = 4;
  if (contentMaxLength) {
    const elm = document.createElement('div');
    elm.innerHTML = contentMaxLength;
    elm.style.fontSize = '12px';
    elm.style.height = 'auto';
    elm.style.width = 'auto';
    elm.style.position = 'absolute';
    elm.style.whiteSpace = 'no-wrap';
    elm.style.opacity = '0';
    document.body.appendChild(elm);
    width = Math.ceil(elm.clientWidth);
    document.body.removeChild(elm);
  }
  const listWidth = width + itemIconWidth + listPaddingX * 2 + itemContentPaddingLeft + delta;
  if (listWidth > 248) return '248px';
  else if (listWidth < 176) return `176px`;
  return `${listWidth}px`;
};

const widthSelectBox = ref<string>(getMaxWidthItemValue(items.value));

const { list, containerProps, wrapperProps, scrollTo } = useVirtualList(items, {
  itemHeight: 36,
  overscan: 10,
});

const currentOption = computed(() => {
  const font = props.options?.find((item) => item.value === props.value);
  return (
    font || {
      label: props.value,
      value: props.value,
    }
  );
});

const [isShow, toggle] = useToggle(false);

const currentIndex = computed(() => {
  return items.value.findIndex((item) => item.value === props.value);
});

const setOpen = () => {
  if (!isShow.value) {
    scrollTo(currentIndex.value);
  }
  toggle(!isShow.value);

  setTimeout(() => {
    // setTimeout: delay load 0s when open dropdown
    loadGoogleFonts();
  }, 0);
};

const setClose = () => {
  toggle(false);
};

const loadGoogleFontByName = (fontFamily: string) => {
  const fontElementSettingClass = 'google-fonts-element';
  const fontUrl = `https://fonts.googleapis.com/css?family=${fontFamily}`;
  const googleFont = document.querySelector(`.${fontElementSettingClass}[data-font="${encodeURI(fontFamily)}"]`);
  if (googleFont) {
    if (googleFont.getAttribute('href') !== fontUrl) {
      googleFont.setAttribute('href', fontUrl);
    }
  } else {
    const link = document.createElement('link');
    link.className = fontElementSettingClass;
    link.dataset.font = encodeURI(fontFamily);
    link.href = fontUrl;
    link.rel = 'stylesheet';
    document.head.appendChild(link);
  }
};
const loadGoogleFonts = () => {
  const fonts = list.value;
  if (fonts?.length) {
    for (let i = 0; i < fonts.length; i++) {
      const font = fonts[i];
      const fontFamily = font.data.value || '';
      loadGoogleFontByName(fontFamily);
    }
  }
};

watch(list, (newVal) => {
  if (newVal) {
    loadGoogleFonts();
  }
});

watch(
  () => keyword.value.trim().toLowerCase(),
  (newVal) => {
    if (newVal) {
      scrollTo(0);
    } else {
      scrollTo(currentIndex.value);
    }
  },
);

onMounted(() => {
  if (currentOption.value?.value) {
    loadGoogleFontByName(currentOption.value.value);
  }
});

const onChange = (value: any) => {
  emit('controlChange', props.id, value);
  setClose();
};
</script>

<template>
  <div v-click-away="setClose">
    <button
      ref="button"
      class="rounded-medium text-12 text-dark-high bg-dark-400 placeholder:text-dark-disabled disabled:border-dark-200 disabled:text-dark-disabled relative flex h-36 w-full items-center border px-8 outline-none transition duration-200 disabled:cursor-not-allowed"
      :class="{
        'hover:bg-dark-200 !border-primary-300': isShow,
        'hover:bg-dark-200 border-transparent': !isShow,
      }"
      @click="() => setOpen()">
      <div class="flex w-full items-center justify-between">
        <span
          class="flex-1 overflow-hidden text-ellipsis whitespace-nowrap text-left"
          :style="{ 'font-family': `${currentOption?.label}`, 'font-weight': '400' }">
          {{ currentOption?.label }}
        </span>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.13313 7.62204C5.31064 7.45932 5.59845 7.45932 5.77596 7.62204L10 11.4941L14.224 7.62204C14.4016 7.45932 14.6894 7.45932 14.8669 7.62204C15.0444 7.78476 15.0444 8.04858 14.8669 8.21129L10.3214 12.378C10.1439 12.5407 9.8561 12.5407 9.67859 12.378L5.13313 8.21129C4.95562 8.04858 4.95562 7.78476 5.13313 7.62204Z"
            fill="#E2E2E2"></path>
        </svg>
      </div>
    </button>
    <div
      ref="select"
      class="rounded-medium text-16 shadow-2dp sm:text-14 bg-dark-400 text-dark-high absolute z-[9999] p-8 focus:outline-none"
      :class="{
        'invisible opacity-0': !isShow,
      }"
      :style="{
        position: strategy,
        top: y ? `${y}px` : '',
        left: x ? `${x}px` : '0px',
        width: widthSelectBox,
      }">
      <div class="bg-dark-300 rounded-medium mb-8 flex h-36">
        <div class="flex aspect-square h-full items-center justify-center">
          <GBaseIcon name="search" width="16" class="shrink-0" />
        </div>
        <input v-model="keyword" placeholder="Search fonts" class="text-12 h-full bg-transparent outline-none" />
      </div>
      <div
        v-bind="containerProps"
        class="scrollbar:!w-[8px] scrollbar:bg-transparent scrollbar-track:!rounded-full scrollbar-track:!bg-dark-300 scrollbar-thumb:!rounded-full scrollbar-track:!cursor-grabbing scrollbar-thumb:!bg-dark-high h-[312px] overflow-x-hidden">
        <div v-bind="wrapperProps">
          <div
            v-for="item in list"
            :key="item.index"
            class="text-12 font-regular before:rounded-medium relative flex h-36 cursor-pointer items-center before:absolute before:inset-0 before:bg-transparent before:transition-all hover:before:bg-white/20"
            :class="{
              'before:bg-white/20': item.data.value === props.value,
            }"
            @click="onChange(item.data.value)">
            <div class="flex aspect-square h-full items-center justify-center">
              <svg
                width="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                :class="{
                  hidden: item.data.value !== props.value,
                }">
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M16.7471 5.30122C16.9912 5.5453 16.9912 5.94103 16.7471 6.18511L7.63139 15.3008C7.51418 15.418 7.35521 15.4839 7.18945 15.4839C7.02368 15.4839 6.86471 15.418 6.7475 15.3008L3.25141 11.8046C3.00734 11.5605 3.00734 11.1648 3.25143 10.9207C3.49551 10.6766 3.89124 10.6766 4.13531 10.9207L7.18946 13.975L15.8632 5.30122C16.1073 5.05714 16.503 5.05714 16.7471 5.30122Z"
                  fill="#00C853" />
              </svg>
            </div>
            <span
              class="overflow-hidden text-ellipsis whitespace-nowrap"
              :style="{ 'font-family': `${item.data.value}`, 'font-weight': '400' }">
              {{ item.data.label }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
