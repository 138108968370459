import { defineStore } from 'pinia';
import type { Maybe, ScreenType } from '@/types/custom';
import type { ThemePageType } from '@/types/graphql';

export type State = {
  init: {
    loading: {
      sections: boolean;
      pushSectionToStore: boolean;
      template: boolean;
      globalStyle: boolean;
      tryBuild: boolean;
      message: string[];
    };
    recovery: boolean;
    isDiscardChange: boolean;
    isUpgradingNewVersion: boolean;
    isConnectToShopifyModal: boolean;
  };
  editing: {
    themeId: string;
    pageId: string;
    pageType: ThemePageType | null;
    pageDefault: boolean;
    sectionCid: string | null;
    componentUid: string | null;
    sectionHoverCid: string | null;
    activeTabId: 'setting' | 'style' | 'advanced';
    componentHoverUid: string | null;
    componentDbClickUid: string | null;
    numberClick: number;
    isAllowActiveParentsDropdown: boolean;
    addSectionPopupState: {
      open: boolean;
      hidePosition: string;
    };
    productId?: string;
    isFocusedTextEditor?: boolean;
    textChildComponentUid?: string | null;
  };
  screen: {
    activeId: ScreenType;
    isActiveTabletMobile: boolean;
  };
  iframe: {
    loaded: boolean;
    overlay: boolean;
  };
  layer: {
    open: boolean;
  };
  mobileOnly?: Maybe<boolean>;
  isDragging: boolean;
  searchKeyword: string | undefined;
  modalActiveId?: string;
  saving: boolean;
  layoutSetting: {
    showHeader: boolean;
    showFooter: boolean;
  };
};

export const useEditorStore = defineStore('editor', {
  state: (): State => ({
    init: {
      loading: {
        sections: true,
        pushSectionToStore: false,
        template: true,
        globalStyle: true,
        tryBuild: false,
        message: [],
      },
      recovery: false,
      isDiscardChange: false,
      isUpgradingNewVersion: false,
      isConnectToShopifyModal: false,
    },
    mobileOnly: false,
    editing: {
      themeId: '',
      pageId: '',
      pageType: null,
      pageDefault: false,
      sectionCid: null,
      componentUid: null,
      activeTabId: 'setting',
      componentHoverUid: null,
      sectionHoverCid: null,
      componentDbClickUid: null,
      isAllowActiveParentsDropdown: false,
      numberClick: 1,
      addSectionPopupState: {
        open: false,
        hidePosition: '',
      },
      isFocusedTextEditor: false,
      textChildComponentUid: '',
    },
    screen: {
      activeId: 'desktop',
      isActiveTabletMobile: false,
    },
    iframe: {
      loaded: false,
      overlay: false,
    },
    layer: {
      open: false,
    },
    isDragging: false,
    searchKeyword: '',
    modalActiveId: '',
    saving: false,
    layoutSetting: {
      showHeader: false,
      showFooter: false,
    },
  }),
  getters: {
    getInitUpgradingNewVersion(state) {
      return state.init.isUpgradingNewVersion;
    },
    getInitLoadingSections(state) {
      return state.init.loading.sections;
    },
    getInitLoadingTemplate(state) {
      return state.init.loading.template;
    },
    getInitLoadingGlobalStyle(state) {
      return state.init.loading.globalStyle;
    },
    getInitConnectToShopifyModal(state) {
      return state.init.isConnectToShopifyModal;
    },
    getEditingThemeId(state) {
      return state.editing.themeId;
    },
    getEditingPageId(state) {
      return state.editing.pageId;
    },
    getEditingSectionCid(state) {
      return state.editing.sectionCid;
    },
    getEditingComponentUid(state) {
      return state.editing.componentUid;
    },
    getComponentHoverUid(state) {
      return state.editing.componentHoverUid;
    },
    getEditingActiveTabId(state) {
      return state.editing.activeTabId;
    },
    getIframeLoaded(state) {
      return state.iframe.loaded;
    },
    getInitLoaded(state) {
      return state.init.loading.pushSectionToStore && !state.init.loading.template && !state.init.loading.globalStyle;
    },
    getLoadingTryBuild(state) {
      return state.init.loading.tryBuild;
    },
    getScreenActiveId(state) {
      return state.screen.activeId;
    },
    getDragging(state) {
      return state.isDragging;
    },
    getIframeOverlay(state) {
      return state.iframe.overlay;
    },
    getLayerOpen(state) {
      return state.layer.open;
    },
    getInitRecovery(state) {
      return state.init.recovery;
    },
    getInitIsDiscardChange(state) {
      return state.init.isDiscardChange;
    },
    getEditingPageType(state) {
      return state.editing.pageType;
    },
    getEditingPageDefault(state) {
      return state.editing.pageDefault;
    },
    getSaving(state) {
      return state.saving;
    },
    getComponentDbClickUid(state) {
      return state.editing.componentDbClickUid;
    },
    getLayoutSetting(state: State) {
      return state.layoutSetting;
    },
    getEditingNumberClick(state) {
      return state.editing.numberClick;
    },
    getEditingProductId(state) {
      return state.editing.productId;
    },
    getIsActiveTabletMobile(state) {
      return state.screen.isActiveTabletMobile;
    },
    getIsAllowActiveParentsDropdown(state) {
      return state.editing.isAllowActiveParentsDropdown;
    },
    getIsFocusedTextEditor(state) {
      return state.editing.isFocusedTextEditor;
    },
    getTextChildComponentUid(state) {
      return state.editing.textChildComponentUid;
    },
  },
  actions: {
    clear() {
      this.$reset();
    },
    setInitUpgradingNewVersion(val: boolean) {
      this.init.isUpgradingNewVersion = val;
    },
    setKeyword(input?: string) {
      this.searchKeyword = input;
    },
    setEditingThemeId(themeId: string) {
      this.editing.themeId = themeId;
    },
    setEditingPageId(pageId: string) {
      this.editing.pageId = pageId;
    },
    setActiveScreenId(screenId: ScreenType) {
      if ((screenId == 'tablet' || screenId == 'mobile') && !this.screen.isActiveTabletMobile) {
        this.screen.isActiveTabletMobile = true;
      }
      if (this.mobileOnly) {
        this.screen.activeId = 'desktop';
      } else {
        this.screen.activeId = screenId;
      }
    },
    setOverlayIframe(value: boolean) {
      this.iframe.overlay = value;
    },
    setDragging(value: boolean) {
      this.isDragging = value;
    },
    setEditingSectionCid(sectionCid: string | null) {
      this.editing.sectionCid = sectionCid;
    },
    setEditingComponentUid(componentUid: string | null) {
      this.editing.componentUid = componentUid;
    },
    setComponentDbClickUid(componentUid: string | null) {
      this.editing.componentDbClickUid = componentUid;
    },
    setComponentHoverUid(componentUid: string | null) {
      this.editing.componentHoverUid = componentUid;
    },
    setSectionHoverCid(sectionCid: string | null) {
      this.editing.sectionHoverCid = sectionCid;
    },
    setEditingActiveTabId(tabId: 'setting' | 'style' | 'advanced') {
      this.editing.activeTabId = tabId;
    },
    setEditingProductId(productId: string) {
      this.editing.productId = productId;
    },
    setInitLoadingSections(value: boolean) {
      this.init.loading.sections = value;
    },
    setInitPushSectionToStore(value: boolean) {
      this.init.loading.pushSectionToStore = value;
    },
    setInitLoadingTemplate(value: boolean) {
      this.init.loading.template = value;
    },
    setInitLoadingTryBuild(value: boolean) {
      this.init.loading.tryBuild = value;
    },
    setAddSectionPopup(value: boolean, hidePosition: string) {
      this.editing.addSectionPopupState = {
        open: value,
        hidePosition,
      };
    },
    setInitLoadingGlobalStyle(value: boolean) {
      this.init.loading.globalStyle = value;
    },
    setIframeLoaded(value: boolean) {
      this.iframe.loaded = value;
    },
    setLayerOpen(value: boolean) {
      this.layer.open = value;
    },
    setInitRecovery(value: boolean) {
      this.init.recovery = value;
    },
    setInitIsDiscardChange(value: boolean) {
      this.init.isDiscardChange = value;
    },
    setInitIsConnectToShopifyModal(value: boolean) {
      this.init.isConnectToShopifyModal = value;
    },
    setMobileOnly(value?: Maybe<boolean>) {
      this.mobileOnly = value;
    },
    setLoadingMessage(value: string[]) {
      this.init.loading.message = value;
    },
    setEditingPageType(value: ThemePageType | null) {
      this.editing.pageType = value;
    },
    setEditingPageDefault(value: boolean) {
      this.editing.pageDefault = value;
    },
    setActiveModal(modalId?: string) {
      this.modalActiveId = modalId;
    },
    setSaving(value: boolean) {
      this.saving = value;
    },
    setLayoutSetting(value: { showHeader: boolean; showFooter: boolean }) {
      if (Object.keys(value).length === 0) return;
      this.layoutSetting = value;
    },
    setEditingNumberClick(value: number) {
      this.editing.numberClick = value;
    },
    setIsAllowActiveParentsDropdown(value: boolean) {
      this.editing.isAllowActiveParentsDropdown = value;
    },
    setIsFocusedTextEditor(value: boolean) {
      this.editing.isFocusedTextEditor = value;
    },
    setTextChildComponentUid(value: string) {
      this.editing.textChildComponentUid = value;
    },
  },
});

export default useEditorStore;
